import { useState, useEffect, useCallback } from 'react'
import {useCollection, useCollectionData, useCollectionOnce} from 'react-firebase-hooks/firestore'

// export const useFirestoreLoadMore = queryFn => {
//     const [query, setQuery] = useState(null)
//     const [last, setLast] = useState(null)
//     const [data, setData] = useState([])
//
//     const [qData, loading, error] = useCollection(query)
//
//     useEffect(() => {
//         setData([])
//         setQuery(queryFn())
//     }, [queryFn])
//
//     useEffect(() => {
//         if (qData && qData.query.isEqual(query)) {
//             console.log("test")
//             setLast(qData.docs[qData.docs.length - 1])
//             //console.log("first: ", data.length, " | second: ", qData.docs.length)
//             setData([...data, ...qData.docs])
//         }
//     }, [qData])
//
//     const more = useCallback(() => {
//         setQuery(queryFn().startAfter(last))
//     }, [queryFn, setQuery, last])
//
//     return [[data, loading, error], more]
// }
//
// function printData(data, qData) {
//     let dataString = ""
//     data.forEach((o) => {
//         dataString += o.id + "\n"
//     })
//     console.log("data: ", dataString)
//     let qDataString = ""
//     qData.forEach((o) => {
//         qDataString += o.id + "\n"
//     })
//     console.log("qData: ", "\n", qDataString)
// }

export const useFirestoreLoadMore = queryFn => {
    const baseValue = 40
    const increment = 200
    const [query, setQuery] = useState(null)
    const [limit, setLimit] = useState(baseValue)
    const [data, setData] = useState([])

    const [qData, loading, error] = useCollection(query)

    useEffect(() => {
        setData([])
        if(queryFn()) setQuery(queryFn().limit(limit))
    }, [queryFn])


    useEffect(() => {
        if (qData && qData.query.isEqual(query)) {
            setData([...qData.docs])
        }
    }, [qData])

    const more = useCallback(() => {
        setQuery(queryFn().limit(limit + increment))
        setLimit(limit + increment)
    }, [queryFn, setQuery])

    return [[data, loading, error], more]
}

export const useFirestoreLoad = queryFn => {
    const [query, setQuery] = useState(null)
    const [data, setData] = useState([])

    const [qData, loading, error] = useCollection(query)

    useEffect(() => {
        setData([])
        setQuery(queryFn())
    }, [queryFn])

    useEffect(() => {
        if (qData && qData.query.isEqual(query)) {
            setData([...qData.docs])
        }
    }, [qData])

    return [[data, loading, error]]
}