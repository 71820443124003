import React, {useState} from "react";
import {auth, db, storage} from "../../firebase"
import Button from "@material-ui/core/Button";
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fade from "@material-ui/core/Fade";
import 'react-image-crop/dist/ReactCrop.css'
import {Divider} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Dialog from "@material-ui/core/Dialog";
import Container from "@material-ui/core/Container";

import ChatIcon from '@material-ui/icons/Chat';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {useCollectionDataOnce} from "react-firebase-hooks/firestore";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import firebase from "firebase/app";
import ProfileDialog from "../dialogs/ProfileDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: 20
    },
    paper: {
        margin: 50,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    media: {
        marginTop: 20,
        width: '60%',
        margin: 'auto',
        aspectRatio: '1/1',
        objectPosition: "center",
        objectFit: "cover",
        borderRadius: '50%'
    },
    card: {
        margin: 20,
        textAlign: 'center',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    buttonGroup: {
        width: 'calc(100% - 100px)',
        marginTop: 30,
        marginInline: 50
    }
}));




function calculateAge(birthday) { // birthday is a date
    if(birthday === null) {
        return 0
    }
    let ageDifMs = Date.now() - birthday.getTime();
    let ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

//import {useHome} from "../../contexts/HomeContext";

export default function Home(props) {
    const classes = useStyles();
    // const [profiles, setProfiles] = useState([])
    // const [docs, setDocs] = useState([])
    const [images, setImages] = useState([])
    const [open, setOpen] = React.useState(false);
    const [select, setSelect] = React.useState(null)
    const [friend, setFriend] = React.useState(false)

    const storageRef = storage.ref()
    const usersRef = db.collection('users')

    const [profiles] = useCollectionDataOnce(usersRef, {idField: 'id'})

    const manageFriend = () => {
        if(friend) {
            db.collection('users').doc(auth.currentUser.uid).update({
                friends: firebase.firestore.FieldValue.arrayRemove(profiles[select].id)
            }).then(() => {
                props.currentUser.friends = props.currentUser.friends.filter(o => o !== profiles[select].id)
                setFriend(false)
            })
        } else {
            db.collection('users').doc(auth.currentUser.uid).update({
                friends: firebase.firestore.FieldValue.arrayUnion(profiles[select].id)
            }).then(() => {
                props.currentUser.friends.push(profiles[select].id)
                setFriend(true)
            })
        }
    }

    const handleClickOpen = (index) => {
        setSelect(index);
        setOpen(true)
        setFriend(() => {
            return props.currentUser.friends.includes(profiles[index].id);
        })
    };

    const handleClose = () => {
        setOpen(false);
    };

    //
    // React.useEffect(() => {
    //     db.collection("users").get().then((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //             // doc.data() is never undefined for query doc snapshots
    //             console.log(doc.id, " => ", doc.data());
    //             setProfiles([...profiles, doc.data()])
    //             setDocs([...docs, doc.id])
    //         });
    //     });
    // })

    // React.useEffect(() => {
    //     const fetchData = async () => {
    //         const data = await db.collection("users").get()
    //         setProfiles(data.docs.map(doc => doc.data()))
    //         setDocs(data.docs.map(doc => doc.id))
    //         console.log("Data: ", profiles)
    //         console.log("docs: ", docs)
    //     }
    //     fetchData()
    // }, [])

    return (
        <Fade in={true}>
            <div>
                {select !== null ? <ProfileDialog
                    handleClose={handleClose}
                    manageFriend={manageFriend}
                    user={profiles[select]}
                    open={open}
                    friend={friend}
                    message={props.message}
                /> : null}
                <Grid container direction="row" justify="center" alignItems="flex-start">
                    {
                        profiles && profiles.map((value, index) => {
                            if(value.id === auth.currentUser.uid) return null
                            //addPicture(value)
                            let learning = []
                            let native = []
                            let languages = value.languages.split(",")
                            languages.forEach(item => {
                                if(item.includes("_")) {
                                    let language = item.split("_")
                                    if(parseInt(language[1]) === 4)
                                        native.push(language[0])
                                    else
                                        learning.push(language[0])
                                }
                            })
                            return <Grid item xs={12} md={6} lg={4} xl={3} key={value.id}>
                                <Card className={classes.card}>
                                    <CardActionArea onClick={() => handleClickOpen(index)}>
                                        <div>
                                            <img key={value.id + "_photo"} className={classes.media} src={value.profilePic[0]}/>
                                        </div>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                {value.name}, {calculateAge(new Date(value.birth))}
                                            </Typography>
                                            <Typography gutterBottom variant="h6" component="h2">
                                                <img src={`/flags/${value.country.toLowerCase().replace(" ", "-")}.svg`} style={{height: 20, width: 20}}/> {value.country}{value.city != null ? `, ${value.city}` : ''}
                                            </Typography>
                                            <Divider style={{marginBottom: 10}}/>
                                            <div>
                                                <Typography gutterBottom align="center"  variant="h6" component="h4">
                                                    Speaks
                                                </Typography>
                                                {native.map((value, index) => {
                                                    if(index === 0) {
                                                        return <img key={value + "_" + index} src={`/languages/${value}.svg`} style={{height: 25, width: 25, marginTop: 5, position: 'relative', zIndex: native.length - index}}/>
                                                    } else {
                                                        return <img key={value + "_" + index} src={`/languages/${value}.svg`} style={{height: 25, width: 25, marginTop: 5, marginLeft: -5, position: 'relative', zIndex: native.length - index}}/>
                                                    }
                                                })}
                                                {/*<List>*/}
                                                {/*    {native.map((value) => {*/}
                                                {/*        return <ListItem style={{height: 50, width: 50}}>*/}
                                                {/*            <ListItemAvatar>*/}
                                                {/*                <Avatar>*/}
                                                {/*                    <img src={`/languages/${value}.svg`} style={{height: '100%', width: '100%'}}/>*/}
                                                {/*                </Avatar>*/}
                                                {/*            </ListItemAvatar>*/}
                                                {/*            <ListItemText primary={value.charAt(0).toUpperCase() + value.slice(1)}/>*/}
                                                {/*        </ListItem>*/}
                                                {/*    })}*/}
                                                {/*</List>*/}
                                            </div>
                                            <div>
                                                <Typography gutterBottom align="center" variant="h6" component="h4">
                                                    Learning
                                                </Typography>
                                                {learning.map((value, index) => {
                                                    if(index === 0) {
                                                        return <img key={value + "_" + index} src={`/languages/${value}.svg`} style={{height: 25, width: 25, position: 'relative', zIndex: native.length - index}}/>
                                                    } else {
                                                        return <img key={value + "_" + index} src={`/languages/${value}.svg`} style={{height: 25, width: 25, marginLeft: -5, position: 'relative', zIndex: native.length - index}}/>
                                                    }
                                                })}
                                                {/*<List>*/}
                                                {/*    {learning.map((value) => {*/}
                                                {/*        return <ListItem>*/}
                                                {/*            <ListItemAvatar>*/}
                                                {/*                <Avatar>*/}
                                                {/*                    <img src={`/languages/${value}.svg`} style={{height: '100%', width: '100%'}}/>*/}
                                                {/*                </Avatar>*/}
                                                {/*            </ListItemAvatar>*/}
                                                {/*            <ListItemText primary={value}/>*/}
                                                {/*        </ListItem>*/}
                                                {/*    })}*/}
                                                {/*</List>*/}

                                            </div>
                                            <Divider style={{marginBottom: 10, marginTop: 20}}/>
                                            <div style={{marginInline: 25}}>
                                                <Typography variant="body1" style={{marginBlock: 20}}>
                                                    Hello, I'm here!!
                                                </Typography>
                                                <Typography align="left" variant="h6" component="h2">
                                                    About me
                                                </Typography>
                                                <Typography align="left" variant="body1" component="p">
                                                    {value.info.about_me.substring(0, 300)}{value.info.about_me.length > 300 ? '...' : null}
                                                </Typography>
                                            </div>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        })
                    }
                </Grid>
            </div>
        </Fade>

    )
}