import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {Divider} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import ChatIcon from "@material-ui/icons/Chat";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import Slide from "@material-ui/core/Slide";
import {makeStyles} from "@material-ui/core/styles";
import {auth} from "../../firebase";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: 20
    },
    paper: {
        margin: 50,
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    media: {
        marginTop: 20,
        width: '60%',
        margin: 'auto',
        aspectRatio: '1/1',
        objectPosition: "center",
        objectFit: "cover",
        borderRadius: '50%'
    },
    card: {
        margin: 20,
        textAlign: 'center',
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    buttonGroup: {
        width: 'calc(100% - 100px)',
        marginTop: 30,
        marginInline: 50
    }
}));

const getLevel = (level) => {
    switch (level) {
        case 2: return "Intermediate"
        case 3: return "Advanced"
        default: return "Beginner"
    }
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProfileDialog(props) {
    const classes = useStyles()
    
    return (
        <Dialog fullScreen open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {props.user.name + '\'s profile'}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container style={{marginTop: 30, paddingBottom: 150}}>
                <Grid container direction="row" justify="center" alignItems="flex-start">
                    <Grid item xs={12} md={6} lg={3}>
                        <img key={"profile-pic"} src={props.user.profilePic[0]} className={classes.media} style={{width: '100%'}}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={9}>
                        <Typography variant="h3" style={{marginTop: '12%', marginLeft: 50}}>
                            {props.user.name}
                        </Typography>
                        <Divider/>
                        <List style={{marginLeft: 10}}>
                            <ListItem key={"one"}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <img key={"flag-icon"} src={`/flags/${props.user.country.toLowerCase().replace(" ", "-")}.svg`} style={{height: '100%', width: '100%'}}/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={props.user.country} secondary={props.user.city != null ? `${props.user.city}` : null}/>
                            </ListItem>
                            <ListItem key={"two"}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <AccessTimeIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="18:01"/>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>

                <Grid container direction="row" justify="center" alignItems="flex-start">
                    <Grid item xs={12} md={7}>
                        {props.user.id !== auth.currentUser.uid ? <ButtonGroup fullWidth className={classes.buttonGroup} variant="contained" color="secondary" aria-label="contained primary button group">
                            <Button onClick={() => props.message(props.user.id)} variant="contained" color="secondary" startIcon={<ChatIcon />}>Send message</Button>
                            {!props.friend ?
                                <Button onClick={props.manageFriend} variant="contained" color="secondary" startIcon={<PersonAddIcon />}>Add to friends</Button> :
                                <Button onClick={props.manageFriend} variant="contained" color="secondary" startIcon={<PersonAddDisabledIcon />}>Unfriend</Button> }
                            <Button onClick={() => console.log("show more")} variant="contained" color="secondary" endIcon={<MoreHorizIcon />}>More</Button>
                        </ButtonGroup> : null}
                        <Typography align="center" style={{marginTop: 50}}>
                            Hellooo, I'm heree
                        </Typography>
                        <Grid container direction="row" justify="center" alignItems="flex-start" style={{marginTop: 50}}>
                            <Grid item xs={12} md={6}>
                                <Typography align="left" variant="h5">
                                    Native in:
                                </Typography>
                                <List>
                                    {props.user.languages.split(",").map((value => {
                                        if(value.includes("_")) {
                                            let languageInfo = value.split("_")
                                            if(parseInt(languageInfo[1]) === 4)
                                                return <ListItem key={languageInfo[0]}>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <img key={languageInfo[0] + "picture"} src={`languages/${languageInfo[0]}.svg`} style={{width: '100%', height: '100%'}}/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={languageInfo[0].charAt(0).toUpperCase() + languageInfo[0].slice(1)} secondary="Native"/>
                                                </ListItem>
                                        }
                                        return null
                                    }))}
                                </List>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography align="left" variant="h5">
                                    Learning:
                                </Typography>
                                <List>
                                    {props.user.languages.split(",").map((value => {
                                        if(value.includes("_")) {
                                            let languageInfo = value.split("_")
                                            if(parseInt(languageInfo[1]) !== 4)
                                                return <ListItem key={languageInfo[0]}>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <img key={languageInfo[0] + "picture"} src={`languages/${languageInfo[0]}.svg`} style={{width: '100%', height: '100%'}}/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={languageInfo[0].charAt(0).toUpperCase() + languageInfo[0].slice(1)} secondary={getLevel(parseInt(languageInfo[1]))}/>
                                                </ListItem>
                                        }
                                        return null
                                    }))}
                                </List>
                            </Grid>
                        </Grid>
                        <Typography style={{marginTop: 60}} variant="h5">
                            About me
                        </Typography>
                        <Typography>
                            {props.user.info.about_me}
                        </Typography>
                        <Typography style={{marginTop: 10}} variant="h5">
                            Looking for
                        </Typography>
                        <Typography>
                            {props.user.info.looking_for}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5} style={{borderLeft: '1.5px solid #dedede', paddingLeft: 15, marginTop: 100}}>
                        <Typography variant="h5">
                            Pictures
                        </Typography>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                            {props.user.profilePic.length <= 1 ? <p>No other pictures</p> :
                                props.user.profilePic.slice(1).map((picture) => <Grid item xs={6} style={{paddingInline: 5, paddingBlock: 1.5}}>
                                    <img key={"one-pic"} src={picture} style={{width: '100%'}}/>
                                </Grid>)}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    )
}

