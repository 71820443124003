import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function DeleteAccount(props) {
    const [confirm, setConfirm] = React.useState(false)

    const handleChange = () => {
        setConfirm(!confirm)
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete your account?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        After confirming the account will be deleted upon not logging in for 30 days.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={confirm}
                                onChange={handleChange}
                                name="confirm"
                                color="primary"
                            />
                        }a
                        label="I understand that I am about to delete my account."
                    />
                    <Button onClick={props.handleClose} color="primary">
                        cancel
                    </Button>
                    <Button disabled={!confirm} onClick={props.handleClose} color="primary" autoFocus>
                        delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}