import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import {auth, db} from "../firebase";
import {useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

export default function RouterManager() {
    const history = useHistory();

    if(auth.currentUser) {
        db.collection('users').doc(auth.currentUser.uid).get().then((doc) => {
            if(doc.exists)
                history.push('/dashboard')
            else
                history.push('/profile')
        })
    } else {
        history.push('/login')
    }


    return (
        <Container>
            <div style={{margin: 'auto', marginTop: 100}}>
                <div style={{ margin: 'auto', width: 250, height:250, marginBottom: 50}}>
                    <CircularProgress style={{width: 250, height: 250}}/>
                </div>

                <Typography variant="h2" align="center">
                    Beep bop, redirecting you to the right page.
                </Typography>
            </div>
        </Container>
    )
}