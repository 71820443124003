import React from 'react';
import ReactDOM from 'react-dom';

import App from "./components/App";

const routing = (
    <App />
)

ReactDOM.render(
  routing,
  document.getElementById('root')
)
