import React, {useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';

import Home from "./dashboard/Home"
import Messages from "./dashboard/Messages"
import Profile from "./dashboard/Profile"
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MessageIcon from '@material-ui/icons/Message';
import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import LogoutDialog from "./dialogs/LogoutDialog";
import {useAuth} from "../contexts/AuthContext";
import {useHistory} from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import {auth, db, rt_db} from "../firebase";
import firebase from "firebase/app";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    fullheight: {
        minHeight: '100%'
    },
}));

export default function Dashboard() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [type, setType] = React.useState(0)
    const [logout, setLogout] = React.useState(false)
    const [target, setTarget] = React.useState('')
    const { Logout } = useAuth();
    const history = useHistory()
    const [currentUser, setCurrentUser] = React.useState(null)

    useEffect(() => {
        if(auth.currentUser) {
            if(!currentUser) {
                db.collection('users').doc(auth.currentUser.uid).get().then((doc) => {
                    if(!doc.exists)
                        history.push('/profile')
                    else {
                        setCurrentUser(doc.data())
                        const ref = rt_db.ref("/users/" + auth.currentUser.uid)
                        ref.update({
                            name: doc.data().name,
                            picture: doc.data().profilePic[0],
                            onlineState: true,
                            lastSeen: firebase.database.ServerValue.TIMESTAMP
                        })
                        ref.onDisconnect().update({
                            onlineState: true,
                            lastSeen: firebase.database.ServerValue.TIMESTAMP
                        })
                    }
                })
            }
        } else {
            history.push('/login')
        }

    })


    function changeContent(content) {
        setType(content)
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    function handleDialog(open) {
        if(open) {
            Logout();
            history.push("/login")
        } else {
            setLogout(false)
        }
    }

    function handleLogout() {
        setLogout(true)
    }

    const newMessage = (uid) => {
        setType(1)
        setTarget(uid)
    }

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    return (
        <div className={classes.root}>
            {logout ? <LogoutDialog dialogCallback={handleDialog}/> : ""}
            <CssBaseline />
            <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Dashboard
                    </Typography>
                    <IconButton color="inherit">
                        <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem button onClick={() => changeContent(0)}>
                        <ListItemIcon><HomeIcon/></ListItemIcon>
                        <ListItemText primary="Homepage"/>
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={() => changeContent(1)}>
                        <ListItemIcon><MessageIcon/></ListItemIcon>
                        <ListItemText primary="Messages"/>
                    </ListItem>
                    <ListItem button onClick={() => changeContent(2)}>
                        <ListItemIcon><AccountCircleIcon/></ListItemIcon>
                        <ListItemText primary="My profile"/>
                    </ListItem>
                </List>
                <Divider/>
                <List>
                    <ListItem button key={"test"}>
                        <ListItemIcon><SettingsIcon/></ListItemIcon>
                        <ListItemText primary="Settings"/>
                    </ListItem>
                    <ListItem button onClick={handleLogout}>
                        <ListItemIcon><ExitToAppIcon/></ListItemIcon>
                        <ListItemText primary="Log out"/>
                    </ListItem>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                {type === 0 ? <Home currentUser={currentUser} message={newMessage}/> : ""}
                {type === 1 ? <Messages target={target} currentUser={currentUser}/> : ""}
                {type === 2 ? <Profile currentUser={currentUser}/> : ""}
            </main>
        </div>
    );
}