import React, {useCallback, useEffect, useRef, useState} from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import {Paper} from "@material-ui/core";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SettingsIcon from '@material-ui/icons/Settings';
import LanguageIcon from '@material-ui/icons/Language';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import DeleteAccount from "../profileActions/DeleteAccount";
import ProfileDialog from "../dialogs/ProfileDialog";
import SettingsDialog from "../dialogs/SettingsDialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {db, storage, auth} from "../../firebase";
import firebase from "firebase/app";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 100
    },
    pic: {
        width: 300,
        borderRadius: '50%'
    },
    buttons: {
        borderRadius: '50%',
        width: 50,
        height: 64,
    },
    buttonIcon: {
        width: 33,
        height: 33,
        color: "white"
    },
    content: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    }, gridList: {
        maxWidth: 500,
        width: '100%'
    },
    optionButton: {
        marginLeft: 5,
        width: '100%',
        height: 55,
        justifyContent: "flex-start",
        textTransform: 'none',
        fontWeight: 400,
        fontSize: 18,
        borderRadius: 0
    },
    link: {
        display: 'block', cursor: 'pointer', marginBottom: 5
    }

}))

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export default function Profile(props) {
    const classes = useStyles()
    const [user, setUser] = React.useState(null)
    const [profilePic, setProfilePic] = React.useState(null)

    //dialogs
    const [deleteOpen, setDeleteOpen] = React.useState(false)
    const [profileOpen, setProfileOpen] = React.useState(false)
    const [settingsOpen, setSettingsOpen] = React.useState(false)
    const [cropOpen, setCropOpen] = React.useState(false)

    const preventDefault = (event) => event.preventDefault();

    useEffect(() => {
        setUser(props.currentUser)
        setProfilePic(props.currentUser.profilePic)
    }, [user, profilePic])

    const handleCropClose = () => {
        setCropOpen(false)
    }

    const handleCropOpen = () => {
        setCropOpen(true)
    }

    const [addProfilePic, setAddProfilePic] = React.useState(null)
    const [profilePicBlob, setProfilePicBlob] = React.useState(new Blob())
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(null);

    //functions
    const setPicture = () => {
        generateDownload(previewCanvasRef.current, crop)
        handleCropClose()
    }

    let onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
        handleCropOpen()
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    function generateDownload(canvas, crop) {
        if (!crop || !canvas) {
            return;
        }

        canvas.toBlob(
            (blob) => {
                const storageRef = storage.ref().child(`profiles/${uuidv4()}.jpg`)
                storageRef.put(blob).then(async () => {
                    const fileUrl = await storageRef.getDownloadURL()
                    db.collection('users').doc(auth.currentUser.uid).update({
                        profilePic: firebase.firestore.FieldValue.arrayUnion(fileUrl)
                    }).then(r => {
                        props.currentUser.profilePic.push(fileUrl)
                    })
                })
            },
            'image/jpeg',
            0.5
        );
    }

    return (
        <Container className={classes.root}>
            <Paper elevation={3} style={{paddingBottom: 30}}>
                <Grid container direction="row" >
                    <input
                        type="file"
                        accept="image/*"
                        ref={(ref) => onSelectFile = ref}
                        onChange={onSelectFile}
                        style={{display: "none"}}
                    />
                    <canvas
                        ref={previewCanvasRef}
                        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                        style={{
                            width: Math.round(completedCrop?.width ?? 0),
                            height: Math.round(completedCrop?.height ?? 0),
                            display: "none"
                        }}
                    />
                    <Grid item xs={12} md={4} style={{padding: 25}}>
                        <div className={classes.content}>
                            <GridList className={classes.gridList} cols={2}>
                                <GridListTile key={1} cols={2} rows={2}>
                                    <div style={{width: '100%', paddingTop: '100%', position: 'relative'}}>
                                        <img style={{top: 0, width: '100%', height: '100%', position: 'absolute'}} src={profilePic ? profilePic[0] : null} />
                                        <IconButton disableFocusRipple={true} style={{position: 'absolute', top: 0, right: 0}}>
                                            <CloseIcon fontSize="large"/>
                                        </IconButton>
                                    </div>
                                </GridListTile>
                                {props.currentUser.profilePic.slice(1).map((picture) => <GridListTile>
                                    <div style={{width: '100%', paddingTop: '100%', position: 'relative'}}>
                                        <img style={{top: 0, width: '100%', height: '100%', position: 'absolute'}} src={picture} />
                                        <IconButton style={{position: 'absolute', top: 0, right: 0}}>
                                            <CloseIcon fontSize="large"/>
                                        </IconButton>
                                    </div>
                                </GridListTile>)}
                                {props.currentUser.profilePic.length < 10 ? <GridListTile>
                                    <div style={{width: '100%', paddingTop: '100%', position: 'relative'}}>
                                        <Button onClick={(e) =>{onSelectFile.click()}} style={{top: 0, width: '100%', height: '100%', position: 'absolute', borderRadius: 0}} variant="contained" size="large"><AddAPhotoIcon style={{width: '30%', height: '30%'}} /></Button>
                                    </div>
                                </GridListTile>: null}
                            </GridList>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5} lg={6} style={{paddingInline: 25, paddingTop: 35}}>
                        <Typography variant="h5" component="h3">
                            Customize account
                        </Typography>
                        <Divider/>
                        <Button startIcon={<AssignmentIndIcon/>} onClick={() => setProfileOpen(true)} className={classes.optionButton}>Preview profile</Button>
                        <Button startIcon={<EditIcon/>} className={classes.optionButton}>Edit Profile</Button>
                        <Button startIcon={<LanguageIcon/>} className={classes.optionButton}>Change languages</Button>
                        <Divider/>
                        <Button startIcon={<SettingsIcon/>} onClick={() => setSettingsOpen(true)} className={classes.optionButton}>Settings</Button>
                        <Button onClick={() => setDeleteOpen(true)} startIcon={<DeleteForeverIcon/>} className={classes.optionButton}>Delete Account</Button>
                    </Grid>
                    <Grid item xs={12} md={3} lg={2} style={{paddingInline: 25, paddingTop: 35}}>
                        <Typography variant="h5" component="h3">
                            Information
                        </Typography>
                        <Divider style={{marginBottom: 10}}/>
                        <div>
                            <Link className={classes.link} onClick={preventDefault} variant="body1">
                                How to's
                            </Link>
                            <Link className={classes.link} onClick={preventDefault}  variant="body1">
                                Rules
                            </Link>
                            <Link className={classes.link} onClick={preventDefault}  variant="body1">
                                Tips for improving your profile
                            </Link>
                        </div>
                    </Grid>
                </Grid>

            </Paper>
            <DeleteAccount open={deleteOpen} handleClose={() => setDeleteOpen(false)}/>
            <ProfileDialog
                open={profileOpen}
                handleClose={() => setProfileOpen(false)}
                user={props.currentUser}
            />
            <SettingsDialog
                open={settingsOpen}
                handleClose={() => setSettingsOpen(false)}
            />
            <Dialog onClose={handleCropClose} aria-labelledby="customized-dialog-title" open={cropOpen}>
                <DialogTitle id="customized-dialog-title" onClose={handleCropClose}>
                    Crop your picture
                </DialogTitle>
                <DialogContent dividers className={classes.modelContent}>
                    <ReactCrop
                        src={upImg}
                        onImageLoaded={onLoad}
                        crop={crop}
                        ruleOfThirds
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={setPicture} color="primary">
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}