import * as React from 'react';
import {auth, db, rt_db} from "../firebase";
import {useFirestoreLoad, useFirestoreLoadLast, useFirestoreLoadMore} from "../firebaseActions";
import Typography from "@material-ui/core/Typography";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

import firebase from "firebase/app";
import {useEffect} from "react";
import Box from "@material-ui/core/Box";

function getLast4(num) {
    return (num + "").slice(7)
}

export default function Test(collectionPath) {
    const chatRef = db.collection("conversations").where("users", "array-contains", auth.currentUser.uid);
    const query = chatRef.orderBy('lastActivity', "desc").orderBy('users')
    const [conversations, setConversations] = React.useState(null)

    const getConversations = async () => {
        const snapshot = await query.get()
        snapshot.forEach(doc => {
            console.log(doc.id, '=>', doc.data());
        });
        setConversations(snapshot)
    }

    return (
        <div>
            {conversations && conversations.forEach((conversation) =>
                <Typography>
                    {conversation.data()}
                </Typography>
            )}
            <ButtonGroup>
                <Button onClick={getConversations}>
                    Get Conversations
                </Button>
            </ButtonGroup>
        </div>
    )
}