import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import SwipeableViews from 'react-swipeable-views';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons"
import Divider from "@material-ui/core/Divider";
import {Box} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import {grey} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import blue from "@material-ui/core/colors/blue";
import Fade from "@material-ui/core/Fade";
import {db, auth} from "../../firebase"

import Chat from '../Chat'
import {useCollectionData} from "react-firebase-hooks/firestore";
import ChatIcon from '@material-ui/icons/Chat';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        height: 'calc(100% - 64px)'
    },
    list: {
        width: 400,
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        borderInlineEndColor: "#d1d1d1",
        borderInlineEnd: "1px solid",
    },
    listNoPadding: {
        padding: 0
    },
    fill: {
        width: '100%',
        height: '100%',
        backgroundColor: "aqua"
    },
    item: {
        width: '100%',
    },
    picture: {
        marginBottom: 10,
        marginTop: 10,
        width: theme.spacing(8),
        height: theme.spacing(8)
    },
    notification: {
        position: "absolute",
        top: 45,
        right: 25
    },
    info: {
        paddingInlineStart: 12,
        margin: "auto",
        marginBottom: 10,
        marginTop: 10,
    },
    title: {
        margin: 3,
        fontWeight: 400,
    },
    message: {
        display: "flex",
        margin: 3,
        fontWeight: 300,
        color: theme.palette.grey
    },
    notificationTitle: {
        margin: 3,
        fontWeight: 700
    },
    notificationMessage: {
        display: "flex",
        wordBreak: "keep-all",
        margin: 3,
        fontWeight: 450,
        color: theme.palette.grey
    },
    from: {
        margin: 0,
        marginRight: 3,
        color: "blue"

    },
    tabs: {
        margin: 0,
        padding: 0,
        width: '100%'
    },
    tab: {
        width: '50%'
    },
    noPadding: {
        padding: 0,
    },
    minusPadding: {
    },
    fullHeight: {
        height: '100%'
    },
    noChat: {
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    noChatContent: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 450,
        minHeight: 450,
        borderRadius: '50%',
        background: '#e2e2e2'
    }
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const fulldays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export function formatDate(someDateTimeStamp) {
    const dt = new Date(someDateTimeStamp),
        date = dt.getDate(),
        diffDays = new Date().getDate() - date,
        diffYears = new Date().getFullYear() - dt.getFullYear();
    if(diffYears === 0 && diffDays === 0){
        return 1
    }else if(diffYears === 0 && diffDays === 1) {
        return 2
    }else if(diffYears === 0 && (diffDays > 1 && diffDays < 7)) {
        return 3
    }else {
        return 4
    }
}

function getTimeText(date) {
    if(date) {
        let time = date.toDate()
        let translateTime = new Date(time.toString())
        //console.log(daysPassed)
        switch (formatDate(Date.now() - (Date.now() - translateTime))) {
            case 1: return translateTime.getHours() + ":" + (translateTime.getMinutes() < 10 ? '0' + translateTime.getMinutes() : translateTime.getMinutes())
            case 2: return "Yesterday"
            case 3: return translateTime.toLocaleDateString('en-us', {weekday: 'long'})
            default: return translateTime.getDate() + "/" + (translateTime.getMonth() + 1) + "/" + translateTime.getFullYear()
        }
    } else {
        return null
    }
}

function compare( a, b ) {
    console.log("sorting")
    if ( a.lastActivity < b.lastActivity ){
        return -1;
    }
    if ( a.lastActivity > b.lastActivity ){
        return 1;
    }
    return 0;
}

export default function Messages(props) {
    const classes = useStyles()
    const theme = useTheme()
    const [value, setValue] = React.useState(0)
    const [selected, setSelected] = React.useState('')
    const [user, setUser] = React.useState(null)


    useEffect(() => {
        if(props.target) {
            setSelected(props.target)
        }
        if(props.currentUser) {
            setUser(props.currentUser)
        }
    }, [])

    const chatRef = db.collection("conversations").where("users", "array-contains", auth.currentUser.uid);
    const query = chatRef.orderBy('lastActivity', "desc").orderBy('users')
    const [recentMessages] = useCollectionData(query, {idField: 'id'})

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    function GetChat(props) {
        const conversation = props.conversation
        const from = props.from
        return <div key={conversation.id + "_div"} style={from === selected ? {background: 'rgba(0,0,0,0.07)'} : {}}>
            <ListItem key={conversation.id} button onClick={() => setSelected(from)} className={classes.minusPadding}>
                <Box display="flex" className={classes.item}>
                    <Box>
                        <Avatar className={classes.picture} src={conversation.userData[from].picture}/>
                    </Box>
                    <Box flexGrow={1} className={classes.info}>
                        <Typography className={classes.notificationTitle} variant="h6" style={{maxWidth: '100%'}}>
                            {conversation.userData[from].name}{conversation.userData[from].name > 20 ? '...' : null}{}
                        </Typography>

                        <Typography className={classes.notificationMessage} >
                            <b style={{fontWeight: 500, color: 'blue', marginRight: 3}}>{conversation.lastActivityUser === auth.currentUser.uid ? 'You: ' : null}</b>{conversation.lastChat ? conversation.lastChat.substring(0, 28) : null}{conversation.lastChat.length > 28 ? '...' : null}
                        </Typography>
                    </Box>
                    <Box alightcontent="flex-end">
                        <Typography className={classes.title}>
                            {getTimeText(conversation.lastActivity)}
                            <Badge className={classes.notification} badgeContent={4} color="primary"/>
                        </Typography>
                    </Box>
                </Box>
            </ListItem>
            <Divider/>
        </div>
    }

        return (
        <Fade in={true}>
            <Box display="flex" p={5} className={classes.root}>
                <Box className={classes.list} borderRight={1} borderColor="grey.300">
                    <div className={classes.fullHeight}>
                        <List className={classes.fullHeight}>
                            <ListItem>
                                <h2>Messages</h2>
                            </ListItem>
                            <Divider/>
                            <ListItem className={classes.noPadding}>
                                <Tabs
                                    className={classes.tabs}
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                >
                                    <Tab label="Everyone" {...a11yProps(0)} className={classes.tab} />
                                    <Tab label="Friends" {...a11yProps(1)} className={classes.tab} />
                                </Tabs>
                            </ListItem>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                                style={{padding: 0}}
                            >
                                <TabPanel value={value} index={0} dir={theme.direction} style={{padding: 0}[0]}>
                                    {recentMessages && recentMessages.filter((message) => {
                                        return message.lastChat
                                    }).map((conversation) => {
                                        return <GetChat
                                            conversation={conversation}
                                            from={conversation.users.find(o => {return o !== auth.currentUser.uid})}/>
                                    })}
                                </TabPanel>
                                <TabPanel value={value} index={1} dir={theme.direction}>
                                    {recentMessages && recentMessages.filter(o => {
                                        if(user) {
                                            if(user.friends) {
                                                if(user.friends.includes(o.users[0]))
                                                    return true
                                                if(user.friends.includes(o.users[1]))
                                                    return true
                                            }
                                        } else return false
                                    }).map((conversation) => {
                                        return <GetChat
                                            conversation={conversation}
                                            from={conversation.users.find(o => {return o !== auth.currentUser.uid})}/>
                                    })}
                                </TabPanel>
                            </SwipeableViews>
                        </List>
                    </div>
                </Box>
                <Box flexGrow={2} style={{maxWidth: 'calc(100% - 400px)'}}>
                    {selected !== '' ? <Chat currentUser={props.currentUser} uid={selected}/> :
                        <div className={classes.noChat}>
                            <div className={classes.noChatContent}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', height: 400, width: 400}}>
                                    <ChatIcon style={{width: 100, height: 100, color: '#8c8c8c'}}/>
                                    <Typography variant="h3" style={{justifySelf: "center", color: '#6b6b6b'}}>
                                        Select a chat
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    }
                </Box>
            </Box>
        </Fade>
    )
}