import React, {useRef, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useAuth} from "../contexts/AuthContext";
import {useHistory} from "react-router-dom";
import {auth, db} from "../firebase";
import Alert from "@material-ui/lab/Alert";
import logo from "../img/logo.png"

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validatePassword(password) {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    console.log(re.test(String(password)))
    return re.test(String(password));
}

function confirmPassword(password, confirmPassword) {
    return password !== confirmPassword;
}

export default function Register() {
    const classes = useStyles();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const { Register } = useAuth()

    const [loading, setLoading] = useState(false)
    const history = useHistory();

    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')

    const [emailError, setEmailError] = useState(false)

    const [passwordError, setPasswordError] = useState(false)

    const [confirmPasswordError, setConfirmPasswordError] = useState(false)

    if(auth.currentUser) {
        db.collection('users').doc(auth.currentUser.uid).get().then((doc) => {
            if(doc.exists)
                history.push('/dashboard')
            else
                history.push('/profile')
        })
    }

    async function handleSubmit(e) {
        e.preventDefault()
        if(emailRef.current.value.length === 0 || passwordRef.current.value.length === 0 || passwordConfirmRef.current.value.length === 0) {
            setErrorText("Please enter all fields")
            setError(true)
            setEmailError(emailRef.current.value.length === 0)
            setPasswordError(passwordRef.current.value.length === 0)
            setConfirmPasswordError(passwordConfirmRef.current.value.length === 0)
            return
        }

        if(emailError) {
            setErrorText("Please enter a valid email.")
            setError(true)
            return
        }

        if(passwordError) {
            setErrorText("Password doesn't meet requirements.")
            setError(true)
            return
        }

        if(confirmPasswordError) {
            setErrorText("Passwords don't match.")
            setError(true)
            return
        }

        try {
            setLoading(true)
            await Register(emailRef.current.value, passwordRef.current.value)
            history.push("/profile")
        } catch {
            setErrorText("Something went wrong...")
            setError(true)
        }
        setLoading(false)
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar style={{width: 100, height: 100}} src={logo}/>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                {error ? <Alert className={classes.form} style={{marginTop: 10}} severity="error">{errorText}</Alert> : null}
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        inputRef={emailRef}
                        autoFocus
                        onChange={(e) => {
                            if(emailError)setEmailError(!validateEmail(e.target.value))
                            if(e.target.value.length === 0) setEmailError(false)
                        }}
                        onBlur={(e) => {
                            if(e.target.value.length > 0)
                                setEmailError(!validateEmail(e.target.value))
                        }}
                        error={emailError}
                        helperText={emailError ? "Please enter a valid email." : ""}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        inputRef={passwordRef}
                        onChange={(e) => {
                            if(passwordError)setPasswordError(!validatePassword(e.target.value))
                            if(e.target.value.length === 0) setPasswordError(false)
                        }}
                        onBlur={(e) => {
                            if(e.target.value.length > 0)
                                setPasswordError(!validatePassword(e.target.value))
                        }}
                        error={passwordError}
                        helperText={passwordError ? "Password does not match requirements." : ""}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="confirm_password"
                        label="Confirm password"
                        type="password"
                        id="confirm_password"
                        autoComplete="current-password"
                        inputRef={passwordConfirmRef}
                        onChange={(e) => {
                            if(confirmPasswordError)setConfirmPasswordError(!confirmPassword(e.target.value, passwordRef.current.value))
                            if(e.target.value.length === 0) setConfirmPasswordError(false)
                        }}
                        onBlur={(e) => {
                            if(e.target.value.length > 0)
                                setConfirmPasswordError(!validatePassword(e.target.value, passwordRef.current.value))
                        }}
                        error={confirmPasswordError}
                        helperText={confirmPasswordError ? "Passwords don't match." : "Password must be at least 8 characters and contain a number."}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="I agree with the "
                    />
                    <Button
                        disabled={loading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/login" variant="body2">
                                {"Already have an account? Log in"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}