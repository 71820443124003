import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog({dialogCallback}) {


    return (
        <Dialog
            open={true}
            maxWidth="xs"
            onClose={() => dialogCallback(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Are you sure you want to log out?"}</DialogTitle>
            <DialogActions>
                <Button onClick={() => dialogCallback(false)} color="primary">
                    No
                </Button>
                <Button onClick={() => dialogCallback(true)} color="primary" autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}