import React from "react";

import Webcam from "react-webcam";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CameraIcon from '@material-ui/icons/Camera';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'rgba(0,0,0,0.64)',
    },
    content: {
        borderRadius: 0,
        width: '100%',
        height: '100%'
    },
    background: {
        background: 'rgba(0,0,0,0)'
    },
    cameraButton: {
        position: 'absolute',
        margin: 0,
        bottom: 10,
        right: 'calc(50% - 32px)',
        borderRadius: '50%',
        width: 50,
        height: 64,
        padding: 0
    },
    controls: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: 100,
        background: 'rgba(0,0,0,0.4)',
        paddingLeft: 8
    },
    buttons: {
        marginTop: 18,
        marginInline: 10,
        borderRadius: '50%',
        width: 50,
        height: 64,
        padding: 0
    },
    text: {
        color: "white",
        fontWeight: 400
    },
    input: {
        fontWeight: 300,
        color: "grey",
        "& .MuiFormLabel-root": {
            color: "#6d6d6d",
        }
    }
}))


export default function WebcamCapture() {
    const classes = useStyles()

    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);
    const [imgMessage, setImageMessage] = React.useState(null)

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
    }, [webcamRef, setImgSrc]);

    const handleCancel = () => {
        setImgSrc(null)
        setImageMessage(null)
    }

    return (
        <Dialog
            open={false}
            className={classes.root}
            fullWidth
            maxWidth="md"
        >
                {!imgSrc ? <Webcam
                        className={classes.content}
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg">

                    </Webcam>
                    :
                    <img src={imgSrc}/>}
            {!imgSrc ? <Button onClick={capture} variant="contained" className={classes.cameraButton}>
                <CameraIcon/>
            </Button>: <div className={classes.controls}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Box item>
                        <Button onClick={handleCancel} variant="contained" className={classes.buttons}>
                            <DeleteIcon/>
                        </Button>
                        <Button variant="contained" className={classes.buttons}>
                            <DoneIcon/>
                        </Button>
                    </Box>
                    <Box item flexGrow={1} style={{paddingInline: 25}}>
                        <TextField inputProps={{
                            className: classes.text
                        }} className={classes.input} fullWidth id="standard-basic" label="Type something..." onChange={(e) => setImageMessage(e.target.value)} />
                    </Box>
                </Box>
            </div>}

        </Dialog>
    );
};