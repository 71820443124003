import React, {useCallback, useEffect, useRef, useState} from "react";
import {storage, auth, db} from '../firebase'
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import TextField from '@material-ui/core/TextField';
import pic from '../img/profile_pic.png'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {useHistory} from "react-router-dom"

import {useProfile} from "../contexts/ProfileContext"
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
//dates

import DateFnsUtils from "@date-io/date-fns";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from "@material-ui/pickers";
import List from "@material-ui/core/List";
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import {Avatar} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from '@material-ui/icons/Cancel';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}

function valueText(value) {
    switch (value) {
        case 2: return `Intermediate`
        case 3: return `Advanced`
        case 4: return `Native`
        default: return `Beginner`
    }
}

function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '10%',
        width: '100%'
    },
    form: {
        padding: 10,
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    owner: {
        width: '50%',
        position: "relative",
        marginBlock: 50,
        marginInline: 'auto',
    },
    picture: {
        width: '100%',
        borderRadius: '50%',
    },
    adderBorder: {
        position: "absolute",
        bottom: 0,
        right: 0,
        width: 75,
        height: 75,
        background: "white",
        borderRadius: '50%'
    },
    adder: {
        margin: 5,
        width: 65,
        height: 65,
        background: 'rgb(141,141,141)',
        borderRadius: '50%',

        "&:hover" : {
            background: 'rgba(39,28,28,0.4)',
            cursor: 'pointer'
        }
    },
    adderIcon: {
        width: 55,
        height: 55,
        margin: 5,
    },
    modelContent: {
        padding: 0,
        margin: 0
    }
}));

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function calculateAge(birthday) { // birthday is a date
    if(birthday === null) {
        return 0
    }
    let ageDifMs = Date.now() - birthday.getTime();
    let ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export default function Profile() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const history = useHistory();

    const [error, setError] = React.useState(false)
    const [errorText, setErrorText] = React.useState('')

    if(auth.currentUser === null) {
        history.push("/login")
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = async () => {
        if(auth.currentUser !== null) {

            const storageRef = storage.ref();
            const imagesRef = storageRef.child(`profiles/${uuidv4()}.jpg`)

            await imagesRef.put(profilePicBlob)
            const fileUrl = await imagesRef.getDownloadURL()


            let languageString = ""
            userLanguages.forEach(item => {
                languageString += item.language + "_" + item.level + ","
            })
            db.collection("users").doc(auth.currentUser.uid).set({
                name: name,
                birth: selectedDate.getTime(),
                info: {
                    about_me: aboutMe,
                    looking_for: lookingFor
                },
                friends: [],
                profilePic: [fileUrl],
                languages: languageString,
                country: country,
                city: city
            })
                .then((docRef) => {
                    history.push("/dashboard")
                })
                .catch((error) =>
                    console.error("Error adding document: ", error)
                )
        } else {
            history.push("/login")
        }
    };


    //step 1
    //data
    const [profilePic, setProfilePic] = React.useState(null)
    const [profilePicBlob, setProfilePicBlob] = React.useState(new Blob())
    const [profilePicBlobSmall, setProfilePicBlobSmall] = React.useState(new Blob())
    const [name, setName] = React.useState('');
    const [nameError, setNameError] = React.useState(false)
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [ageError, setAgeError] = React.useState(false)

    const [step1, setStep1] = React.useState(true)

    useEffect(() => {
        setStep1((name.length === 0 || calculateAge(selectedDate) < 16 || !profilePic))
    }, [name, selectedDate, profilePic])

    //dialog
    const [open, setOpen] = React.useState(false)

    //handlers
    const handleDateChange = (date) => {
        date.setUTCSeconds(0)
        date.setUTCMilliseconds(0)
        date.setUTCHours(0)
        date.setUTCMinutes(0)
        if(calculateAge(date) < 16) {
            setError(true)
            setErrorText("You have to be atleast 16 years old.")
            setAgeError(true)
        } else {
            setAgeError(false)
            setSelectedDate(date);
        }
    };

    const handleCropClose = () => {
        setOpen(false)
    }

    const handleCropOpen = () => {
        setOpen(true)
    }

    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(null);

    //functions
    const setPicture = () => {
        generateDownload(previewCanvasRef.current, crop)
        handleCropClose()
    }

    let onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
        handleCropOpen()
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    }, [completedCrop]);

    function generateDownload(canvas, crop) {
        if (!crop || !canvas) {
            return;
        }

        canvas.toBlob(
            (blob) => {
                setProfilePic(window.URL.createObjectURL(blob));
                setProfilePicBlob(blob)
            },
            'image/jpeg',
            0.5
        );
        canvas.toBlob(
            (blob) => {
                setProfilePicBlobSmall(blob)
            },
            'image/jpeg',
            0.2
        );
    }

    //step 2
    //data
    const [country, setCountry] = React.useState(null)
    const [city, setCity] = React.useState('')

    //step 3
    //data
    const [aboutMe, setAboutMe] = React.useState('')
    const [lookingFor, setLookingFor] = React.useState('')

    const [step3, setStep3] = React.useState(true)

    useEffect(() => {
        setStep3(!(aboutMe.length > 0) || !(lookingFor.length > 0))
    }, [aboutMe, lookingFor])

    //step 4
    //data
    const [languageOpen, setLanguageOpen] = React.useState(false)
    const [userLanguages, setUserLanguages] = React.useState([])
    const [search, setSearch] = useState('')

    const [step4, setStep4] = React.useState(true)

    useEffect(() => {
        let native = false, learning = false
        userLanguages.forEach(language => {
            if(language.level === 4)
                native = true
            else
                learning = true
        })
        setStep4(!(native && learning))
    },[userLanguages])

    //handlers
    const handleLanguageClose = () => {
        setLanguageOpen(false)
    }

    const handleLanguageOpen = () => {
        setSearch('')
        setLanguageOpen(true)
    }

    //functions
    const adjustLevel = index => (event, newValue) => {
        let newArray = [...userLanguages]
        newArray[index].level = newValue
        setUserLanguages(newArray)
    }

    function addLanguage(language) {
        setUserLanguages([...userLanguages, {language: language, level: 1}])
        handleLanguageClose()
    }

    function removeLanguage(language) {
        setUserLanguages(userLanguages.filter(item => {
            return item.language !== language.language
        }))
    }

    return(
        <div>
            <Snackbar open={error} onClose={() => setError(false)} autoHideDuration={6000}>
                <Alert onClose={() => setError(false)} severity="error">
                    {errorText}
                </Alert>
            </Snackbar>
            <Dialog onClose={handleCropClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleCropClose}>
                    Crop your picture
                </DialogTitle>
                <DialogContent dividers className={classes.modelContent}>
                    <ReactCrop
                        src={upImg}
                        onImageLoaded={onLoad}
                        crop={crop}
                        ruleOfThirds
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={setPicture} color="primary">
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{position: "absolute"}}>
                <Dialog onClose={handleLanguageClose} open={languageOpen}>
                    <DialogTitle onClose={handleLanguageClose}>
                        Choose a language
                    </DialogTitle>
                    <DialogContent style={{width: 400}}>
                        <TextField focused autoFocus id="outlined-search" label="Search field" autoComplete="off" type="search" variant="outlined" fullWidth onChange={e => setSearch(e.target.value)} />
                        <List>
                            {languages.filter(item => {
                                    if(userLanguages.find(o => o.language === item))
                                        return false
                                    if(!search) return true
                                    if(item.toLowerCase().includes(search.toLowerCase()))
                                        return true
                                }
                            ).map((language) =>
                                <ListItem button onClick={() => addLanguage(language)}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <img style={{width: '100%', height: '100%'}} src={`/languages/${language}.svg`}/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={language.charAt(0).toUpperCase() + language.slice(1)}/>
                                </ListItem>
                            )}
                        </List>
                    </DialogContent>
                </Dialog>
            </div>

            <Container component="main" maxWidth="sm">
                <div className={classes.root}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        <Step key="Some things about you!">
                            <StepLabel>Some things about you!</StepLabel>
                            <StepContent>
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={(ref) => onSelectFile = ref}
                                    onChange={onSelectFile}
                                    style={{display: "none"}}
                                />
                                <div className={classes.owner}>
                                    <img src={profilePic == null ? pic : profilePic} className={classes.picture}/>
                                    <div className={classes.adderBorder}>
                                        <div onClick={(e) =>{onSelectFile.click()}}  className={classes.adder}>
                                            <AddIcon className={classes.adderIcon}/>
                                        </div>
                                    </div>
                                </div>

                                <canvas
                                    ref={previewCanvasRef}
                                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                    style={{
                                        width: Math.round(completedCrop?.width ?? 0),
                                        height: Math.round(completedCrop?.height ?? 0),
                                        display: "none"
                                    }}
                                />
                                <TextField
                                    value={name}
                                    id="outlined-basic"
                                    label="Name"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={nameError}
                                    helperText={nameError ? "Please fill in your name." : "TIP: First name basis is more than okay! No need to add a second name."}
                                    onChange={(e) => {
                                        setName(e.target.value)
                                        setNameError(e.target.value.length === 0)
                                    }}
                                />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        inputVariant="outlined"
                                        style={{width: '100%'}}
                                        margin="normal"
                                        id="date-picker-dialog"
                                        label="Birth date"
                                        format="dd/MM/yyyy"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        disableFuture={true}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            disabled={step1}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>Location</StepLabel>
                            <StepContent>
                                <Autocomplete
                                    id="country-select-demo"
                                    style={{ width: '100%', marginBottom: 20 }}
                                    options={countries}
                                    classes={{
                                        option: classes.option,
                                    }}
                                    autoHighlight
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => setCountry(value ? value.label : null)}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <span>{countryToFlag(option.code)}</span>
                                            {option.label} ({option.code}) +{option.phone}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            label="Choose a country"
                                            variant="outlined"
                                            autoComplete="off"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                                <TextField id="outlined-basic" label="Choose a city" variant="outlined" fullWidth helperText="Optional." onChange={e => setCity(e.target.value)}/>
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            disabled={!country}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>Some things about you!</StepLabel>
                            <StepContent>
                                <TextField
                                    label="About you"
                                    required
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    helperText="Be creative! This will be people's first impression of you. People form an opinion on you in less than 20 seconds, better make them count!"
                                    style={{marginBottom: 20}}
                                    onChange={e => setAboutMe(e.target.value)}
                                />
                                <TextField
                                    label="Looking for"
                                    required
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    onChange={e => setLookingFor(e.target.value)}
                                />
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            disabled={step3}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>Languages</StepLabel>
                            <StepContent>
                                <Typography>Now onto the good stuff! What languages do you speak?</Typography>
                                <List>
                                    {userLanguages.map((language, index) =>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <img style={{width: '100%', height: '100%'}} src={`/languages/${language.language}.svg`}/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={language.language.charAt(0).toUpperCase() + language.language.slice(1)} secondary={valueText(language.level)}/>
                                            <div style={{width: 160}}>
                                                <Slider
                                                    id={language.language}
                                                    ValueLabelComponent={ValueLabelComponent}
                                                    defaultValue={language.level}
                                                    getAriaValueText={valueText}
                                                    aria-labelledby="continuous-slider"
                                                    valueLabelFormat={value => <div>{valueText(value)}</div>}
                                                    step={1}
                                                    marks
                                                    min={1}
                                                    max={4}
                                                    aria-valuetext="Test"
                                                    valueLabelDisplay="auto"
                                                    onChange={adjustLevel(index)}
                                                />
                                            </div>
                                            <IconButton style={{marginLeft: 10}} onClick={() => removeLanguage(language)}>
                                                <CancelIcon/>
                                            </IconButton>
                                        </ListItem>
                                    )}
                                    <ListItem button onClick={handleLanguageOpen}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <AddIcon style={{height: '85%', width: '85%'}}/>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Add"/>
                                    </ListItem>
                                </List>
                                <Typography variant="caption">Keep in mind, you need atleast 1 native language. And don't be shy! Choose a few languages you are currently learning</Typography>
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            disabled={step4}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                        >
                                            Finish
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    </Stepper>
                    {activeStep === 4 && (
                        <Paper square elevation={0} className={classes.resetContainer}>
                            <Typography>All steps completed - you&apos;re finished</Typography>
                            <Button onClick={handleReset} className={classes.button}>
                                Finish profile
                            </Button>
                        </Paper>
                    )}
                </div>
            </Container>
        </div>

    )
}

const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    { code: 'AE', label: 'United Arab Emirates', phone: '971' },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    { code: 'AU', label: 'Australia', phone: '61', suggested: true },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    { code: 'CA', label: 'Canada', phone: '1', suggested: true },
    { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
    { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243' },
    { code: 'CF', label: 'Central African Republic', phone: '236' },
    { code: 'CG', label: 'Congo, Republic of the', phone: '242' },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    { code: 'DE', label: 'Germany', phone: '49', suggested: true },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
    { code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    { code: 'FR', label: 'France', phone: '33', suggested: true },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    { code: 'GS', label: 'South Georgia and the South Sandwich Islands', phone: '500' },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    { code: 'JP', label: 'Japan', phone: '81', suggested: true },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
    { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850' },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', phone: '389' },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    { code: 'PS', label: 'Palestine, State of', phone: '970' },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
    { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
    { code: 'TD', label: 'Chad', phone: '235' },
    { code: 'TF', label: 'French Southern Territories', phone: '262' },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
    { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    { code: 'US', label: 'United States', phone: '1', suggested: true },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
    { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    { code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
    { code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];

const languages = [
    "abkhaz",
    "afrikaans",
    "akan",
    "albanian",
    "amharic",
    "ancient_greek",
    "arabic",
    "ashante",
    "armenian",
    "assamese",
    "avar",
    "azerbaijani",
    "balochi",
    "bambara",
    "basque",
    "bavarian",
    "belarusian",
    "bengali",
    "berber",
    "bhojpuri",
    "bosnian",
    "bulgarian",
    "burmese",
    "cantonese",
    "catalan",
    "cebuano",
    "chavacano",
    "chechen",
    "chinese",
    "croatian",
    "czech",
    "danish",
    "dari",
    "dargwa",
    "dyula",
    "dutch",
    "edo",
    "english",
    "estonian",
    "ewe",
    "finnish",
    "french",
    "fula",
    "ga",
    "galician",
    "georgian",
    "german",
    "greek",
    "greenlandic",
    "gujarati",
    "haitian_creole",
    "hawaiian",
    "hebrew",
    "hindi",
    "hungarian",
    "icelandic",
    "igbo",
    "ilocano",
    "indonesian",
    "italian",
    "irish",
    "japanese",
    "javanese",
    "kazakh",
    "korean",
    "kurdish",
    "kyrgyz",
    "latvian",
    "laz",
    "lingala",
    "lithuanian",
    "luganda",
    "macedonian",
    "malay",
    "malayalam",
    "maltese",
    "mandarin",
    "mongolian",
    "montenegrin",
    "mossi",
    "nepali",
    "norwegian",
    "polish",
    "portuguese",
    "romanian",
    "russian",
    "samoan",
    "serbian",
    "shanghainese",
    "shona",
    "sichuan",
    "sicilian",
    "sinhalese",
    "slovak",
    "slovene",
    "somali",
    "spanish",
    "sudanese_arabic",
    "sundanese",
    "swedish",
    "swiss_german",
    "sylheti",
    "tagalog",
    "taiwanese",
    "tajik",
    "tamil",
    "tatar",
    "telugu",
    "thai",
    "tigre",
    "tigrinya",
    "tongan",
    "tshiluba",
    "turkish",
    "ukrainian",
    "urdu",
    "uyghur",
    "uzbek",
    "vietnamese",
    "visayan",
    "welsh",
    "wolof",
    "yoruba",
    "zulu"
]