import React, {useContext, useEffect, useState} from "react";
import {auth} from "../firebase"

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    function Register(email, password) {
        return auth.createUserWithEmailAndPassword(email, password)
    }

    function Login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function Logout() {
        localStorage.clear()
        return auth.signOut()
    }

    useEffect(() => {
        return auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        });
    }, [])

    const value = {
        currentUser,
        Register,
        Login,
        Logout
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}