import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {AppBar} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";

import PersonIcon from '@material-ui/icons/Person';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import Fade from "@material-ui/core/Fade";
import Switch from "@material-ui/core/Switch";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    icon: {
        width: 40,
        height: 40
    }
}));

export default function SettingsDialog(props) {
    const classes = useStyles()
    const [visible, setVisible] = React.useState(true)

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            fullWidth
            maxWidth="xs"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Settings"}</DialogTitle>
            <List style={{width: '100%'}}>
                <ListItem>
                    <ListItemAvatar>
                            {visible ? <Fade in={visible}><PersonIcon fontSize="large"/></Fade> : <Fade in={!visible}><PermIdentityIcon fontSize="large"/></Fade>}
                    </ListItemAvatar>
                    <ListItemText primary="Profile Visible"/>
                    <Switch
                        checked={visible}
                        onChange={() => setVisible(!visible)}
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                </ListItem>
            </List>
        </Dialog>
    )
}