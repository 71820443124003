import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import PrivateRoute from "./PrivateRoute"

import Container from "@material-ui/core/Container";
import {AuthProvider} from "../contexts/AuthContext";

import Dashboard from "./Dashboard"
import Register from "./Register";
import Login from "./Login";
import Profile from "./Profile";
import RouterManager from "./RouterManager";
import Test from "./Test";

function App() {
  return (
          <Router>
              <AuthProvider>
                  <Switch>
                      <PrivateRoute exact path="/dashboard" component={Dashboard}/>
                      <PrivateRoute exact path="/profile" component={Profile}/>
                      <Route path="/register" component={Register}/>
                      <Route path="/login" component={Login}/>
                      <Route path="/test" component={Test}/>
                      <Route path="/" component={RouterManager}/>
                  </Switch>
              </AuthProvider>
          </Router>
  );
}

export default App;
