import * as React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { flexbox } from '@material-ui/system';
import List from "@material-ui/core/List";

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import ImageIcon from '@material-ui/icons/Image';
import {Avatar} from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import MicIcon from '@material-ui/icons/Mic';
import Fade from "@material-ui/core/Fade";
import Zoom from "@material-ui/core/Zoom";
import { Scrollbars } from 'react-custom-scrollbars';
import IconButton from "@material-ui/core/IconButton";
import {auth, db, rt_db} from "../firebase";
import {useCollectionData} from "react-firebase-hooks/firestore";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import {useEffect, useRef} from "react";
import Webcam from "./Webcam";
import Linkify from 'react-linkify';
import Divider from "@material-ui/core/Divider";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Badge from "@material-ui/core/Badge";
import {useFirestoreLoad, useFirestoreLoadMore} from "../firebaseActions";
import firebase from "firebase/app";
import {useList} from "react-firebase-hooks/database";
import {formatDate} from "./dashboard/Messages";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    box: {
        display: 'flex',
        flexDirection: 'column'
    },
    chat: {
        maxWidth: '100%',
        background: "#f2f2f2",
        flexGrow: 1,
        maxHeight: 'calc(100% - 130px)',
        paddingBottom: 25
    },
    input: {
        marginBottom: 6, marginTop: 3
    },
    icon: {
        height: 50,
        width: 50,
        marginTop: 5,
    },
    sendIcon: {
        width: 50,
        height: 50,
        position: "absolute",
        marginLeft: 20,
        marginRight: 35,
        top: -30,
    },
    sendIconBackground: {
        width: '100%',
        height: '100%',
        background: theme.palette.primary.main,
        borderRadius: '50%',

        "&:hover" : {
            background: theme.palette.primary.dark,
            cursor: 'pointer'
        }
    },
    sendIconChange: {
        position: 'absolute',
        width: 50,
        height: 50,
        color: "white",
        padding: 10
    },
    chatInput: {
        width: '100%',
        marginTop: 10,
        marginRight: 10,
        fontSize: 18
    },
    to: {
        maxWidth: '100%',
        justifyContent:'flex-end',
        float: 'right',
        border: "1px solid #cfcfcf",
        background: "#ffffff",
        borderRadius: 25,
    }, from: {
        maxWidth: 'calc(100% - 35px)',
        justifyContent:'flex-end',
        float: 'right',
        background: "#e2e2e2",
        borderRadius: 25,
        marginLeft: 5
    },
    textPadding: {
        padding: 8,
        paddingInline: 15,
    },
    chatBar: {
        width: '100%',
        height: 65,
        background: "#ffffff",
        borderBottomColor: "#d1d1d1",
        borderBottom: "1px solid",
    }
}));

const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank">
        {text}
    </a>
);

// const isLink = (message) => {
//     let pattern = /\b(?:(?:https):\/\/|[-A-Z0-9+&@#/%=~_|$?!:,.]+\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/i
//     return pattern.test(message)
// }
//
// const getTitle = (message) => {
//     let pattern = /\b(?:(?:https):\/\/|[-A-Z0-9+&@#/%=~_|$?!:,.]+\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/i
//     let url = message.match(pattern)[0]
//     return fetch(`https://crossorigin.me/${url}`)
//         .then((response) => response.text())
//         .then((html) => {
//             const doc = new DOMParser().parseFromString(html, "text/html");
//             const title = doc.querySelectorAll('title')[0];
//             return title.innerText;
//         });
// };

const checkNext = (current, next) => {
    if(!next) {
        return true
    }
    return (next.from !== current.from) || (next.to !== current.to);
}

const getTime = () => {
    return "Some time"
}


function getTimeText(date) {
    if(date) {
        let time = date.toDate()
        let translateTime = new Date(time.toString())
        //console.log(daysPassed)
        switch (formatDate(Date.now() - (Date.now() - translateTime))) {
            case 1: return "Today, " + translateTime.getHours() + ":" + (translateTime.getMinutes() < 10 ? '0' + translateTime.getMinutes() : translateTime.getMinutes())
            case 2: return "Yesterday, " + translateTime.getHours() + ":" + (translateTime.getMinutes() < 10 ? '0' + translateTime.getMinutes() : translateTime.getMinutes())
            case 3: return translateTime.toLocaleDateString('en-us', {weekday: 'long'}) + ", " + translateTime.getHours() + ":" + (translateTime.getMinutes() < 10 ? '0' + translateTime.getMinutes() : translateTime.getMinutes())
            default: return translateTime.toLocaleDateString('en-us', {month: 'short'}) + ' ' + translateTime.getDate() + ", " + translateTime.getHours() + ":" + (translateTime.getMinutes() < 10 ? '0' + translateTime.getMinutes() : translateTime.getMinutes())
        }
    } else {
        return null
    }
}

const showPicture = (next) => {
    if(!next) return true
    return next.data().from === auth.currentUser.uid;
}

let counter = 0;

const showTime = (previous, current, currentObject) => {
    counter++
    if(!previous) return false
    if(!previous.message) return true
    const t1 = previous.createdAt.toDate(), t2 = current.createdAt.toDate()
    const dt1 = new Date(t1.toString()), dt2 = new Date(t2.toString())
    return dt2.getTime() - dt1.getTime() > 600_000;
}


const Message = (props) => {
    const msg = props.msg
    const classes = props.classes
    const toUser = props.toUser
    let {message, from, to, createdAt} = msg.data()
    if(!message) {
        return <ListItem key={"welcome"} style={{width: '100%'}}>
            <div style={{width: '100%'}}>
                <Avatar style={{width: 130, height: 130, marginLeft: 'calc(50% - 65px)', marginBottom: 10}} src={toUser.hasOwnProperty('profilePic') ? toUser.profilePic[0] : null}/>
                <Typography  align="center" variant="h6">
                    {toUser.hasOwnProperty('name') ? toUser.name : "Deleted User"}
                </Typography>
                <Typography style={{color: 'grey'}} align="center" variant="body2">
                    Beginning of your chat!
                </Typography>
            </div>
        </ListItem>
    }
    const time = () => {
        if(!props.previous) return false
        if(!props.previous.data().message) return true
        if(!createdAt) {
            const t1 = props.previous.data().createdAt.toDate()
            const dt1 = new Date(t1.toString()), dt2 = new Date()
            return dt2.getTime() - dt1.getTime() > 600_000;
        } else {
            const t1 = props.previous.data().createdAt.toDate(), t2 = createdAt.toDate()
            const dt1 = new Date(t1.toString()), dt2 = new Date(t2.toString())
            return dt2.getTime() - dt1.getTime() > 600_000;
        }
    }
    if(from === auth.currentUser.uid) {
        return <div>
            {time() ? <ListItem key={msg.id + "_time"}>
                <Typography variant="body2" align="center" style={{width: '100%', color: 'grey'}}>
                    {getTimeText(createdAt)}
                </Typography>
            </ListItem> : null}
            <ListItem key={msg.id} style={{display:'flex', justifyContent: 'flex-end', padding: 3, marginLeft: '10%', maxWidth: '90%'}}>

                <div style={{display: 'block', maxWidth: '100%'}}>
                    <div className={classes.to}>
                        {/*{isLink(message) ? <div>*/}
                        {/*    /!*{getTitle(message)}*!/*/}
                        {/*    <Divider/>*/}
                        {/*</div> : null }*/}
                        <Linkify componentDecorator={componentDecorator}>
                            <Typography variant="body1"
                                        component="p"
                                        align="left"
                                        className={classes.textPadding}
                                        style={{ wordWrap: "break-word" }}
                            >
                                {message}
                            </Typography>
                        </Linkify>
                    </div>
                </div>
            </ListItem>
        </div>
    } else {
        return <div>
            {time() ? <ListItem key={msg.id + "_time"}>
                <Typography variant="body2" align="center" style={{width: '100%', color: 'grey'}}>
                    {getTimeText(createdAt)}
                </Typography>
            </ListItem> : null}
            <ListItem key={msg.id} style={{display: 'flex', justifyContent: 'flex-start', padding: 3, maxWidth: '90%'}}>
                <div style={{display: 'block', maxWidth: '100%'}}>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <div style={{height: '100%', width: 30, position: 'relative'}}>
                                {showPicture(props.next) ? <Avatar style={{width: 30, height: 30, position: 'absolute', bottom: 0, left: 0}} src={toUser.hasOwnProperty('profilePic') ? toUser.profilePic[0] : null}/> : null}
                            </div>

                        </Box>
                        <Box className={classes.from}>
                            {/*{isLink(message) ? <div>*/}
                            {/*    It's a link!*/}
                            {/*</div> : null }*/}
                            <Linkify componentDecorator={componentDecorator}>
                                <Typography variant="body1"
                                            component="p"
                                            align="left"
                                            className={classes.textPadding}
                                            style={{ wordWrap: "break-word" }}
                                >
                                    {message}
                                </Typography>
                            </Linkify>
                        </Box>
                    </Box>
                    {/*<Typography variant="body2" align="left" style={{marginLeft: 10, color: 'grey'}}>*/}
                    {/*    {new Date(createdAt.seconds * 1000).toISOString().substring(11, 16)}*/}
                    {/*</Typography>*/}
                </div>
            </ListItem>
        </div>
    }
}

export default function Chat(props) {
    const classes = useStyles()
    const [message, setMessage] = React.useState('')
    const [hover, setHover] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const [toUser, setToUser] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [conversation, setConversation] = React.useState(null)
    const [snapshot, setSnapshot] = React.useState(null)
    const [timeStamp, setTimeStamp] = React.useState(0)
    //const [messages, setMessages] = React.useState([])

    const [webcam, setWebcam] = React.useState(false)
    const [bottom, setBottom] = React.useState(true)
    const [top, setTop] = React.useState(false)
    const [newMessage, setNewMessage] = React.useState(0)

    const dummy = useRef()

    useEffect(() => {
        if(!loading)
            scrollDown()
    }, [loading])

    const users = [props.uid, auth.currentUser.uid]
    users.sort()
    const conversationReference = db.collection('conversations').doc(users[0] + "_" + users[1])
    const chatReference = conversationReference.collection('messages')

    useEffect(() => {
        setLoading(true)
        db.collection('users').doc(props.uid).get().then((doc) => {
            if(doc.exists) {
                setToUser(doc.data())
                conversationReference.get().then((conversation) => {
                    if(!conversation.exists) {
                        conversationReference.set({
                            users: users,
                            userData: {
                                [props.uid]: {
                                    name: doc.data().name,
                                    picture: doc.data().profilePic[0]
                                },
                                [auth.currentUser.uid]: {
                                    name: props.currentUser.name,
                                    picture: props.currentUser.profilePic[0]
                                }
                            }
                        }).then(() => {
                            conversationReference.collection('messages').add({
                                createdAt: new Date()
                            }).then(() => {
                                setConv()
                            })})
                    } else {
                        // let from = props.uid
                        // let to = auth.currentUser.uid
                        // if(conversation.data().userData.from.name !== doc.data().name || conversation.data().userData.from.picture !== doc.data().profilePic[0]) {
                        //     conversationReference.update({
                        //         userData: {
                        //             [from]: {
                        //                 name
                        //             }
                        //         }
                        //     })
                        // }
                        setConv()
                    }
                })
            }
        })
        let ref = db.collection('information').doc('time')
        ref.set({
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
        }).then(r => {
            ref.get().then((doc) => {
                setTimeStamp(doc.data().timestamp)
            })
        })
    }, [props.uid])

    useEffect(() => {
        if(conversation)
            setLoading(false)
    }, [conversation])


    const setConv = () => {
        conversationReference.get().then((doc) => {
            setConversation(doc.data())
        })
    }

    const queryFn = React.useCallback(() => {
        if(timeStamp) {
            let q = conversationReference
                .collection('messages')
                .orderBy('createdAt', 'desc')
                .startAt(timeStamp)
            return q
        }
    }, [timeStamp])

    const [[messages, queryLoading, error], more] = useFirestoreLoadMore(queryFn)

    const newQuery = React.useCallback(() => {
        if(timeStamp) {
            let q = conversationReference
                .collection('messages')
                .orderBy('createdAt', 'asc')
                .startAt(timeStamp)
            return q
        }
    }, [timeStamp])

    const [[newMessages, newQueryLoading, newError]] = useFirestoreLoad(newQuery)

    const sendChat = () => {
        if(message.length > 0) {
            chatReference.add({
                to: props.uid,
                from: auth.currentUser.uid,
                message: message,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            }).then((docRef) => {
                conversationReference.update({
                    lastChat: message,
                    lastActivity: firebase.firestore.FieldValue.serverTimestamp(),
                    lastActivityUser: auth.currentUser.uid
                }).then(() => {
                    setMessage('')
                })
            }).catch((error) => {
                console.log("Error sending message")
            })
        }
    }

    const handleInput = (event) => {
        event.preventDefault()
        setMessage(event.target.value)
    }

    //scrolling
    useEffect(() => {
        if(newMessages && dummy.current && bottom)
            scrollDown()
        if(!bottom) {
            setNewMessage(newMessage + 1)
        }

    }, [newMessages])

    useEffect(() => {
        if(bottom)
            setNewMessage(0)
    }, [bottom])

    useEffect(() => {
        if(top && messages[messages.length - 1].data().message) {
            more()
        }
    }, [top])

    //menu

    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleScroll = (event) => {
        //console.log("Scrolling..", event.target.scrollHeight, event.target.scrollTop, event.target.clientHeight)
        const scrollBottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight
        const scrollTop = event.target.scrollTop === 0 && messages.length >= 20
        if(scrollBottom !== bottom)
            setBottom(scrollBottom)
        if(scrollTop !== top)
            setTop(scrollTop)
    }

    const scrollDown = () => {
        dummy.current.scrollIntoView({behaviour: 'smooth'})
    }

    const getNext = (index, old) => {
        if(old) {
            if(index < messages.length - 1) {
                return messages[messages.length - 2 - index]
            }
            if(index === messages.length - 1 && newMessages.length !== 0) {
                return newMessages[0]
            }
            else if(newMessages.length === 0) {
                return null
            }
            return null
        } else {
            if(index === newMessages.length) return null
            else return newMessages[index + 1]
        }
    }

    const getPrevious = (index, old) => {
        if(old) {
            if(index === 0) return null
            return messages[messages.length - index]
        } else {
            if(index === 0) return messages[0]
            return newMessages[index - 1]
        }
    }

    return (
        <div style={{width: '100%', height: '100%'}}>
            {!loading ? <div className={classes.root}>
                <div style={{position: 'absolute', right: 40, bottom: 100, width: 50, height: 50}}>
                    <Slide direction="down" in={!bottom} mountOnEnter unmountOnExit>
                        <IconButton onClick={scrollDown} aria-label="scrollDown" style={{width: 50, height: 50, zIndex: 10}}>
                            <Badge color="secondary" badgeContent={newMessage}>
                                <ExpandMoreIcon style={{width: 35, height: 35}}/>
                            </Badge>

                        </IconButton>
                    </Slide>
                </div>
                <Popper style={{zIndex: 10}} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={handleClose}>Chat Settings</MenuItem>
                                        <MenuItem onClick={handleClose}>Report user</MenuItem>
                                        <MenuItem onClick={handleClose}>Block user</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                <Webcam/>
                <Fade in={!loading}>
                    <Box
                        className={classes.box}
                        display="flex"
                        height={"100%"}
                        style={{maxHeight: '100%', maxWidth: '100%'}}
                        width={"100%"}
                    >
                        <Box className={classes.chatBar}>
                            <Box display="flex">
                                <Box style={{padding: 10, marginLeft: 25}}>
                                    <Avatar
                                        onMouseEnter={() => setHover(true)}
                                        onMouseLeave={() => setHover(false)}
                                        onClick={() => console.log("Clicked!")}
                                        style={ hover ? {width: 45, height: 45, cursor: "pointer"} : {width: 45, height: 45}}
                                        src={toUser.hasOwnProperty('profilePic') ? toUser.profilePic[0] : null}/>
                                </Box>
                                <Box onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => console.log("Clicked!")} flexGrow={1} style={ hover ? {paddingBlock: 10, height: 65, cursor: "pointer"} : {paddingBlock: 10, height: 65}}>
                                    <Typography style={{verticalAlign: 'middle', fontWeight: 500}} variant="body1">
                                        {toUser.hasOwnProperty('name') ? toUser.name : "Deleted User"}
                                    </Typography>
                                    <Typography style={{verticalAlign: 'middle'}} variant="body2">
                                        {toUser.hasOwnProperty('onlineState') ? (toUser.onlineState ? "Online" : getTime()) : "Offline"}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        ref={anchorRef}
                                        aria-controls={open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleToggle}
                                        style={{width: 45, height: 45, margin: 10}}>
                                        <MoreVertIcon style={{width: 30, height: 30}}/>
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.chat}>
                            <div style={{height: '100%', width: '100%', position: 'relative'}}>
                                <List style={{paddingBottom: 15, paddingInline: 20, maxHeight:'100%', overflow: 'auto', width: '100%', position: 'absolute', bottom: 0}} onScroll={handleScroll}>
                                    {messages && messages.map((msg, index, array) =>
                                        <Message next={getNext(index, true)} previous={getPrevious(index, true)} msg={array[array.length - 1 - index]} classes={classes} toUser={toUser}/>
                                    )}
                                    {newMessages && newMessages.map((msg, index, array) =>
                                        <Message next={getNext(index, false)} previous={getPrevious(index, false)} msg={msg} classes={classes} toUser={toUser}/>
                                    )}
                                    <div ref={dummy}></div>
                                </List>
                            </div>
                        </Box>
                        <Box className={classes.input}>
                            <Box display="flex" style={{paddingLeft: '3vh'}} width={"100%"}>
                                <Box flexGrow={1}>
                                    <InputBase
                                        value={message}
                                        multiline={true}
                                        rowsMax={6}
                                        onChange={handleInput}
                                        className={classes.chatInput}
                                        placeholder="Enter message"
                                        inputProps={{ 'aria-label': 'enter messages' }}
                                        onKeyPress={event => {
                                            if(event.key === 'Enter' && !event.shiftKey) {
                                                event.preventDefault()
                                                sendChat()
                                            }
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <IconButton onClick={() => console.log(toUser)} className={classes.icon}>
                                        <PhotoCameraIcon style={{height: 30, width: 30}}/>
                                    </IconButton>
                                </Box>
                                <Box>
                                    <IconButton className={classes.icon}>
                                        <ImageIcon style={{height: 30, width: 30}}/>
                                    </IconButton>
                                </Box>
                                <Box>
                                    <IconButton  className={classes.icon}>
                                        <EmojiEmotionsIcon style={{height: 30, width: 30}}/>
                                    </IconButton>
                                </Box>
                                <Box style={{width: 130, position: 'relative'}}>
                                    <div className={classes.sendIcon}>
                                        <div className={classes.sendIconBackground} onClick={sendChat}>
                                            <div style={{position: 'relative', height: '100%', width: '100%'}}>
                                                <Zoom in={message.length > 0} >
                                                    <SendIcon className={classes.sendIconChange} style={{marginLeft: 2}}/>
                                                </Zoom>
                                                <Zoom in={message.length === 0}>
                                                    <MicIcon className={classes.sendIconChange}/>
                                                </Zoom>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </div> : <div style={{position: 'relative', height: '100%', width: '100%'}}>
                <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} />
            </div>}
        </div>
    )
}